<template>
  <div class="chart chart-pie" ref="pie"/>
</template>

<script>
import {onMounted, ref} from 'vue';
import {PieChart} from '@toast-ui/chart';

export default {
  name: "ToastPieChart",
  props: {
    data: {
      required: true,
      default: []
    },
    colors: Array,
    width: {type: Number, default: 220},
    height: {type: Number, default: 220},
    radiusInner: {type: String, default: '46%'},
    radiusOuter: {type: String, default: '100%'},
    clockwise: {type: Boolean, default: false}
  },
  setup(props){
    const pie = ref(null);
    const chart = ref(null);

    const data = {
      series: props.data
    };

    const options = {
      chart: { width: props.width, height: props.height },
      // xAxis: {
      //   pointOnColumn: false,
      //   // title: { text: 'Month' }
      // },
      // yAxis: { title: 'Temperature (Celsius)' },
      legend: {
        visible: false
      },
      series: {
        solid: true,
        radiusRange:{
          inner: props.radiusInner,
          outer: props.radiusOuter
        },
        dataLabels: {
          visible: false,
        },
        clockwise: props.clockwise
      },
      tooltip: {
        template: (model) => {
          if(model.data[0].label){
            return `<div class="toastui-chart-tooltip" style="border: 1px double #FFBC00;border-radius: 20px;background: #FFFFFF;"><div class="toastui-chart-tooltip-series-wrapper" style="font-weight: normal; color:black; font-size: 12px;"><div class="toastui-chart-tooltip-series"><span class="toastui-chart-series-name"><i class="toastui-chart-icon" style="background: ${model.data[0].color}"></i><span class="toastui-chart-name" style="color:black;">${model.data[0].label}</span></span><span class="toastui-chart-series-value">${Math.round(model.data[0].percentValue)}%</span></div></div></div>`;
          }
          return '';
        }
      },
      theme: {
        series: {
          areaOpacity: 0.7,
          colors: (props.colors ? props.colors : ['#ffcc00','#545045','#8d8080','#84888b','#e8e8e8']),
        },
      },
      exportMenu: {
        visible: false
      },
      lang: {
        noData: '😭 데이터가 없습니다.'
      },
      usageStatistics: false
    };

    onMounted(()=>{
      const el = pie.value;
      chart.value = new PieChart({el, data, options});
    });

    return {
      pie
    }
  }
}
</script>
